import * as React from "react";
import Layout from "../components/layout"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

const AboutPage = (props) => {
    let title = "About Us | Sacramento Web Design, SEO, Web Development"
    let keywords = ["Pyxel Development", "Sacramento Website", "Pyxel Dev"]
    let location = props.location.pathname
    return (
        <div>
            <Layout>
                <Seo title={title} keywords={keywords} location={location} />
                <div className="bg-offblue">
                    <div className="container mx-auto py-32 md:py-40 -mb-24 lg:py-56 lg:-mb-24 xl:py-40 xl:mb-4">
                        <div className="lg:grid md:grid-cols-2">
                            <div>
                                <h1 className="font-pixeboy text-6xl py-4 px-4 text-white">MEET THE TEAM</h1>
                                <div className="flex text-base font-light text-center lg:text-sm xl:text-base justify-center 2xl:justify-start space-x-4 px-4">
                                    <div>
                                        <StaticImage className="px-4 max-w-xs md:max-w-sm mx-auto" src="../images/max-portrait.jpg" placeholder="none" alt="Pyxel Development Founder" title="Pyxel Development Founder" />
                                        <p className="font-bold pt-2">Max Domashuk<br /></p>
                                        <p>Founder<br /></p>
                                    </div>
                                </div>
                            </div>
                            <div className="py-4 lg:py-32 md:py-4">
                                <hr className="lg:hidden border border-black mx-5" />
                                <div className="ml-0 mt-4 lg:ml-8 lg:-mt-11 text-left md:text-center px-4 lg:text-left text-base lg:text-sm lg:leading-8 xl:text-base xl:leading-8 leading-8 2xl:py-8">
                                    <p className=" xl:py-7 2xl:py-16 2xl:-ml-8" >Pyxel Development is your solution to all your Website, Web Design, Digital Marketing, and SEO (Search Engine Optimization) needs. We are a small team located in Downtown Sacramento, California, that has a strong passion
                                        for technology and want to use our knowledge and expertise to help your business succeed. Our experienced and knowledgable developers could help bring your ideas to life, or create a fully custom web design that that will give your
                                        business an advantage over your competitors. </p>
                                    <p className="mt-3 xl:-mt-1 2xl:-mt-16 2xl:-ml-8"><br />Our team is dedicated in providing you with the upmost customer service and will not be satisfied until you are. We believe in quality over quantity, and will work hard to build strong and
                                        long lasting relationships with our clients.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-white">
                        <div className="container mx-auto px-5">
                            {/* <h2 className="uppercase text-5xl font-pixeboy text-center pt-10 md:pt-20 font-bold">Check-out some of our work!</h2>
                            <div className="max-w-lg mx-auto border shadow-xl rounded-xl hover:opacity-80 hover:text-gray-600 mt-8 md:mt-10">
                                <a href="https://www.urbandivine.net/" target="_blank" rel="noreferrer" alt="Urban Devine Construction Website Design" title="Urban Devine Construction Website Design">
                                    <StaticImage src="../images/udc-web-design.png" placeholder="none" className="border rounded-xl h-auto z-0" alt="Urban Devine Construction Website Design" title="Urban Devine Construction Website Design" />
                                    <div className="px-2 py-5">
                                        <h2 className="text-xl font-bold text-center">Urban Divine Construction</h2>
                                    </div>
                                </a>
                            </div> */}
                            <div className="text-center py-10 md:py-20 mb-2 md:mb-0">
                                <p className="font-bold text-5xl px-4 tracking-wide">WE ARE READY FOR YOU!</p>
                                <p className="font-light px-2 py-5 text-sm md:text-base max-w-xl mx-auto">Are you ready to take the online presence of your business to the next level? Contact us now, we are excited to work with you!</p>
                                <Link to="/request-quote" title="Request a Free Quote" alt="Request a Free Quote">
                                    <button className=" h-10 px-6 transition-colors bg-transparent rounded-lg border border-black focus:shadow-outline hover:bg-black hover:text-white">
                                        <span>Request Quote</span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}
export default AboutPage
